<template>
    <div class="SalaryMsg flex">
        <div style="width: 700px;height: 100%;">
            <textarea  placeholder="请输入" v-model="textarea" class="textarea" @input="textareaChange"/>
            <div style="text-align: center">
                <a-button type="primary" class="sub-btn" @click="_submit">提交</a-button>
            </div>
        </div>

        <div style="flex: 1; margin-left: 25px;height: 90%;">
            <div class="table">
                <div class="item" v-for="(item,index) in list">
                    <div class="item-header">{{item.name}}({{item.date}})</div>
                    <div class="item-content">
                        <ul class="flex" style="width:100%">
                            <li v-for="tab in item.list">
                                <div>{{tab.title}}</div>
                                <div>{{tab.money}}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SalaryMsg",
    data(){
        return {
            textarea: '',
            list: [],
        }
    },
    methods: {
        _submit(){
            if(this.textarea){
                this.$axios.post(8000125,{
                    data: JSON.stringify(this.list)
                },(res)=>{
                    if(res.data.code == 1){
                        this.$message.success('数据保存成功');
                        this.textarea = '';
                        this.list = [];
                    }else{
                        this.$message.error(res.data.message);
                    }

                })
            }

        },
        textareaChange(){
            let str = this.textarea.replace(/\s/g,"").split('同学');
            let titleList = ['底薪','岗位津贴','技能津贴','工龄工资','绩效奖','思可基金','工资调增','工资调减','税前总额','社保','公积金','个税','实发工资'];
            let list = [];
            for(let i = 1;i < str.length;i++){
                let del = str[i].substr(63).replace(/(\.\d{2}(?!$))/g,'$1,').split(',');
                if(i < str.length - 1){
                    del.splice(del.length-1,1);
                }
                let delList = [];

                titleList.map((res,index)=>{
                    delList.push({
                        title: res,
                        money: del[index]
                    })
                })
                let item = {
                    name: i == 1 ? str[0] : str[i-1].substr(90,100).replace(/[^\D]/g,'').replace(/\./g,''),
                    date: str[i].substr(7,8),
                    list: delList,
                }
                list.push(item);
            }
            this.list = list;
            console.log(this.list)
        }
    }
}
</script>

<style scoped lang="less">
.SalaryMsg{
    width: 100%;
    height: 100%;
    padding: 15px;
    background: #fff;
    border-radius: 0;
    align-items: end;
    .table{
        border: 1px solid #eee;
        height: 100%;
        overflow-y: scroll;
        .item-header{
            padding: 10px;
            border-bottom: 1px solid #eee;
        }
        .item-content{
            padding: 10px 15px;
            border-bottom: 1px solid #eee;
            ul{
                li{
                    text-align: center;
                    div{
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }
    .sub-btn{
        margin-top: 15px;
        width: 120px;
        height: 38px;
        line-height: 38px;
        font-size: 18px;
    }
    .textarea{
        width: 100%;
        height: 90%;
        background: #fff;
        padding: 10px;
        border-radius: 5px;
        resize: none;
        border-color: #eee;
        outline: none;
        font-size: 14px;
    }
}
</style>